import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

export default function FacebodyStudentInfo() {
  const navigate = useNavigate()
  const [studentDetail, setStudentDetail] = useState({})

  useEffect(() => {
    document.title = '学生信息'

    axios.post('/c/facebody/student/get')
      .then(function (response) {
        if (response.errcode === 0) {
          setStudentDetail(response.data)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }, [])

  const nextStep = () => {
    navigate('/prepare')
  }

  return (
    <div className='facebody-info-page'>
      <div className='facebody-info-bg'></div>
      <div className='facebody-info-photo'>
        <img src={studentDetail.photo} alt="学生照片" />
      </div>
      <div className='facebody-info-item'>
        <div className='facebody-info-label'>姓名</div>
        <div className='facebody-info-content'>{studentDetail.name}</div>
      </div>
      <div className='facebody-info-item'>
        <div className='facebody-info-label'>身份证号</div>
        <div className='facebody-info-content'>{studentDetail.id_number}</div>
      </div>
      <div className='facebody-info-button' onClick={() => { nextStep() }}>下一步</div>
    </div>
  )
}
