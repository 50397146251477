import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { message } from 'antd'

export default function FacebodyLogin() {
  const { oid } = useParams()
  const navigate = useNavigate()
  const [categoryDetail, setCategoryDetail] = useState({})
  const [nameInput, setNameInput] = useState('')
  const [idNumberInput, setIdNumberInput] = useState('')

  useEffect(() => {
    document.title = '人脸核身学生登录'

    axios.post('/c/facebody/category/detail/get', { facebody_category_oid: oid })
      .then(function (response) {
        if (response.errcode === 0) {
          setCategoryDetail(response.data)
        } else {
          message.warning(response.msg)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }, [oid])

  const handleLogin = () => {
    if (!nameInput) {
      message.warning('请输入姓名')
      return false
    }
    if (!idNumberInput) {
      message.warning('请输入身份证号')
      return false
    }

    axios.post('/c/facebody/login', {
      facebody_category_oid: oid,
      name: nameInput,
      id_number: idNumberInput
    })
      .then(function (response) {
        if (response.errcode === 0) {
          message.success('登录成功')
          sessionStorage.setItem('token', response.data.access_token)
          if (response.data.verification_status === 2) {
            navigate('/success', { state: response.data.report })
          } else {
            navigate('/student/info')
          }
        } else if (response.errcode === 2) {
          navigate('/lock')
        } else {
          message.warning(response.msg)
        }
      })
      .catch(function (error) {
        console.error('网络请求异常:', error);
      })
  }

  return (
    <div className='facebody-login-page'>
      <div className='facebody-login-bg'></div>
      <div className='facebody-login-merchantName'>{categoryDetail.merchant_name}</div>
      <div className='facebody-login-categoryName'>{categoryDetail.category_name}</div>
      <div className='facebody-login-descrption'>{categoryDetail.category_descrption}</div>
      <form className='facebody-login-form'>
        <div className='facebody-login-item'>
          <label className='facebody-login-label' htmlFor="studentName">姓名</label>
          <input className='facebody-login-input'
            type='text'
            id='studentName'
            placeholder='请输入姓名'
            value={nameInput}
            onChange={(e) => setNameInput(e.target.value)}
          />
        </div>

        <div className='facebody-login-item'>
          <label className='facebody-login-label' htmlFor="studentNumber">身份证号</label>
          <input className='facebody-login-input'
            type='text'
            id='studentNumber'
            placeholder='请输入身份证号'
            value={idNumberInput}
            onChange={(e) => setIdNumberInput(e.target.value)}
          />
        </div>

        <button
          className='facebody-login-button'
          type='button'
          onClick={handleLogin}>
          登录
        </button>
      </form>
    </div>
  )
}
