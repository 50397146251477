import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { message } from 'antd'

export default function FacebodyPrepare() {
  const navigate = useNavigate()

  useEffect(() => {
    document.title = '视频采集'

    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      message.error('浏览器不支持视频权限请求')
    }
  }, [])

  const checkPermissionsAndNavigate = async () => {
    try {
      await requestVideoPermission()
      setTimeout(() => { // 延迟跳转
        navigate('/capture')
      }, 100)
    } catch (error) {
      console.error('无法获取视频权限:', error)
      alert('请允许访问摄像头以继续')
    }
  }

  const requestVideoPermission = () => {
    return new Promise((resolve, reject) => {
      navigator.mediaDevices.getUserMedia({ video: true })
        .then(stream => {
          stream.getTracks().forEach(track => track.stop())
          resolve()
        })
        .catch(error => reject(error))
    })
  }

  return (
    <div className='facebody-prepare-page'>
      <div className='facebody-prepare-bg'></div>
      <div className='facebody-prepare-title'>采集人脸信息核实身份</div>

      <div className='facebody-prepare-example'>
        <img src="/images/facebody-shili.png" alt="" />
      </div>

      <div className='facebody-prepare-tags'>
        <div className='facebody-prepare-tag'>
          <img src="/images/facebody-gou.png" alt="" />
          <span>正对手机</span>
        </div>
        <div className='facebody-prepare-tag'>
          <img src="/images/facebody-gou.png" alt="" />
          <span>光线充足</span>
        </div>
        <div className='facebody-prepare-tag'>
          <img src="/images/facebody-gou.png" alt="" />
          <span>脸部置于取景框内</span>
        </div>
      </div>

      <div className='facebody-prepare-button' onClick={checkPermissionsAndNavigate}>开始识别</div>
    </div>
  )
}
