import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import axios from 'axios'

export default function FacebodyCategory() {
  const { oid } = useParams()
  const navigate = useNavigate()
  const [categoryList, setCategoryList] = useState([])

  useEffect(() => {
    document.title = '人脸核身'

    axios.post('/c/facebody/category/list/get', { merchant_oid: oid })
      .then(function (response) {
        if (response.errcode === 0) {
          setCategoryList(response.data)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }, [oid])

  const getStatusStyles = (code) => {
    switch (code) {
      case 1:
        return { background: '#FF99331A', color: '#FF9933' }
      case 2:
        return { background: '#446dde1a', color: '#446dde' }
      case 3:
        return { background: '#2625251A', color: '#262525' }
      default:
        return { background: '#2625251A', color: '#262525' }
    }
  }

  const jumpDetail = (oid) => {
    navigate('/login/' + oid)
  }

  return (
    <div className='facebody-category-page'>
      <div className='facebody-category-bg'></div>
      {categoryList.map(item => (
        <div className='facebody-category-box' key={item.oid} onClick={() => { jumpDetail(item.oid) }}>
          <div className='facebody-category-title'>{item.name}</div>
          <div className='facebody-category-status' style={getStatusStyles(item.status.code)}>
            {item.status.msg}
          </div>
          {item.descrption && <div className='facebody-category-description'>{item.descrption}</div>}
          <div className='facebody-category-button'>进入</div>
        </div>
      ))}
    </div>
  )
}
