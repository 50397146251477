import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { message } from 'antd'

export default function FacebodySuccess() {
  const location = useLocation()
  const downloadUrl = location.state

  useEffect(() => {
    document.title = '身份核验'
  }, [])

  const handleDownload = () => {
    if (/MicroMessenger/i.test(navigator.userAgent)) {
      navigator.clipboard.writeText(downloadUrl).then(() => {
        message.success('下载链接已复制，请在浏览器中打开下载或打印')
      }).catch(err => {
        console.error('复制失败: ', err)
      })
    } else {
      window.open(downloadUrl, '_blank')
    }
  }

  return (
    <div className='facebody-result-page'>
      <div className='facebody-result-bg'></div>
      <div className='facebody-result-icon'>
        <img src="/images/facebody-chenggong.png" alt="" />
      </div>
      <div className='facebody-result-title'>核验成功</div>
      <div className='facebody-result-description'>
        您的人脸验证已通过，请下载核验报告并打印，在入校报道时提交！
      </div>
      <div className='facebody-info-button' onClick={() => { handleDownload() }}>下载报告</div>
    </div>
  )
}
