import React, { useEffect } from 'react'
import axios from 'axios'
import { useNavigate, useLocation } from 'react-router-dom'

export default function FacebodyFailed() {
  const navigate = useNavigate()
  const location = useLocation()
  const msg = location.state?.msg

  useEffect(() => {
    document.title = '身份核验'
  }, [])

  const reValidate = () => {
    axios.post('/c/facebody/verification_failure/check')
      .then(function (response) {
        if (response.errcode === 0) {
          navigate('/student/info')
        } else {
          navigate('/lock')
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  return (
    <div className='facebody-result-page'>
      <div className='facebody-result-bg'></div>
      <div className='facebody-result-icon'>
        <img src="/images/facebody-shibai.png" alt="" />
      </div>
      <div className='facebody-result-title'>核验失败</div>
      <div className='facebody-result-description'>
        您的人脸验证未通过，原因可能是: <span style={{ color: '#FF3343' }}>{msg}</span>
      </div>
      <div className='facebody-info-button' onClick={() => { reValidate() }}>重新验证</div>
    </div>
  )
}
