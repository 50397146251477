import React, { useEffect, useRef, useState} from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

export default function FacebodyCapture() {
  const navigate = useNavigate()
  const videoRef = useRef(null)
  const mediaRecorderRef = useRef(null)
  const streamRef = useRef(null)
  const [validateData, setValidateData] = useState('')

  useEffect(() => {
    document.title = '视频采集'

    axios.post('/c/facebody/validate_data/get')
      .then(function (response) {
        if (response.errcode === 0) {
          setValidateData(response.msg)
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })

    const videoElement = videoRef.current
    navigator.mediaDevices.getUserMedia({ video: true })
      .then((stream) => {
        if (videoElement) {
          videoElement.srcObject = stream
        }
        streamRef.current = stream

        const mediaRecorder = new MediaRecorder(stream)
        mediaRecorderRef.current = mediaRecorder

        let chunks = []
        mediaRecorder.ondataavailable = (event) => {
          if (event.data.size > 0) {
            chunks.push(event.data)
          }
        }

        mediaRecorder.onstop = () => {
          const blob = new Blob(chunks, { type: 'video/webm' })
          chunks = []
          const reader = new FileReader()
          reader.onload = () => {
            const base64String = reader.result
            navigate('/checking', { state: { videoBase64: base64String } })
          }
          reader.readAsDataURL(blob)
        }

        mediaRecorder.start()
        setTimeout(() => {
          mediaRecorder.stop()
        }, 5000)
      })
      .catch((err) => {
        console.error('无法获取视频流:', err);
      })

    return () => {
      if (videoElement && videoElement.srcObject) {
        const tracks = videoElement.srcObject.getTracks()
        tracks.forEach((track) => track.stop())
      }
    }
  }, [navigate])

  return (
    <div className='facebody-capture-page'>
      <div className='facebody-capture-box'>
        <img src="/images/facebody-guang.png" alt="" />
        <div className='facebody-capture-video'>
          <video ref={videoRef} autoPlay playsInline muted></video>
        </div>
      </div>
      <div className='facebody-capture-prompt'>{validateData}</div>
    </div>
  )
}
