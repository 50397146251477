import { 
  RouterProvider,
  createBrowserRouter
} from 'react-router-dom'
import FacebodyCategory from './pages/category'
import FacebodyLogin from './pages/login'
import FacebodyStudentInfo from './pages/info'
import FacebodyPrepare from './pages/prepare'
import FacebodyCapture from './pages/capture'
import FacebodyChecking from './pages/checking'
import FacebodySuccess from './pages/success'
import FacebodyFailed from './pages/failed'
import FacebodyLock from './pages/lock'

const router = createBrowserRouter([
  {
    path: '/category/:oid',
    element: <FacebodyCategory />,
  },
  {
    path: '/login/:oid',
    element: <FacebodyLogin />,
  },
  {
    path: '/student/info',
    element: <FacebodyStudentInfo />,
  },
  {
    path: '/prepare',
    element: <FacebodyPrepare />,
  },
  {
    path: '/capture',
    element: <FacebodyCapture />,
  },
  {
    path: '/checking',
    element: <FacebodyChecking />,
  },
  {
    path: '/success',
    element: <FacebodySuccess />,
  },
  {
    path: '/failed',
    element: <FacebodyFailed />,
  },
  {
    path: '/lock',
    element: <FacebodyLock />,
  }
])

export default function Router() {
  return (
    <RouterProvider router={router} />
  )
}
