import React, { useEffect } from 'react'

export default function FacebodyLock() {
  useEffect(() => {
    document.title = '身份核验'
  }, [])

  return (
    <div className='facebody-result-page'>
      <div className='facebody-result-bg'></div>
      <div className='facebody-result-icon'>
        <img src="/images/facebody-suoding.png" alt="" />
      </div>
      <div className='facebody-result-title'>已锁定</div>
      <div className='facebody-result-description'>
        验证失败次数过多，请等待 <span style={{ color: '#FF9933' }}>1</span> 小时候后重试。
      </div>
    </div>
  )
}
