import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'

export default function FacebodyChecking() {
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    document.title = '身份核验'

    if (location.state && location.state.videoBase64) {
      axios.post('/c/facebody/liveness_compare', { 
        video_base64: location.state.videoBase64
      })
        .then(function (response) {
          if (response.errcode === 0) {
            navigate('/success', { state: response.data.report })
          } else  if (response.errcode === 3) {
            navigate('/failed', { state: { msg: response.msg } })
          }
        })
        .catch(function (error) {
          console.error('请求失败，错误信息为：', error)
        })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='facebody-result-page'>
      <div className='facebody-result-bg'></div>

      <div className='facebody-result-icon'>
        <div class="ball-container">
          <div class="ball-half"></div>
          <div class="ball-half"></div>
        </div>
      </div>
      
      <div className='facebody-result-title'>核对中</div>
      <div className='facebody-result-description'>人像核对中，请耐心等待。</div>
    </div>
  )
}
